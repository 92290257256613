import React, { useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "./Header.scss";
import logoVimTo from "../img/logo_vimto.svg";
import {
  setCookieConsentAgree,
  displayCookieConsentInfo,
} from "../js/cookieConsent";

function Header(data) {
  useEffect(() => {
    displayCookieConsentInfo();
  }, []);

  return (
    <div className="header-container" id="header">
      <AnchorLink to="/#header" className="header-container-logo">
        <img
          src={logoVimTo}
          height="32"
          className="header-container-logo-svg"
          alt="VimTo Logo"
        />
      </AnchorLink>
      <div className="header-container-menu">
        <AnchorLink to="/#info" className="text-hover-effect">
          {data.data.allStrapiHeader.nodes[0].Menu1}
        </AnchorLink>
        <AnchorLink to="/#faq" className="text-hover-effect">
          {data.data.allStrapiHeader.nodes[0].Menu2}
        </AnchorLink>
        <AnchorLink to="/#pricing" className="text-hover-effect">
          {data.data.allStrapiHeader.nodes[0].Menu3}
        </AnchorLink>
        <AnchorLink to="/#footer" className="text-hover-effect">
          {data.data.allStrapiHeader.nodes[0].Menu4}
        </AnchorLink>
      </div>
      <div className="header-container-account">
        <a
          rel="nofollow"
          href="/zak"
          className="button-hover-effect header-container-account-button-login"
        >
          {data.data.allStrapiHeader.nodes[0].ForStudent}
        </a>
        <a
          rel="nofollow"
          href="/ucitel"
          className="button-hover-effect header-container-account-button-register"
        >
          {data.data.allStrapiHeader.nodes[0].ForTeacher}
        </a>
      </div>

      <div id="cookieConsent">
        <div class="cookieConsentWrapper">
          <div class="cookieConsentContent">
            Tento web využívá cookies (především k analýze návštěvnosti a sběru
            dat za účelem cílení reklamy). &nbsp;
            <a
              href="/cookies"
              style={{
                color: "#fff",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Upravit nastavení
            </a>
            &nbsp; &nbsp;
            <a
              className="button-hover-effect header-container-account-button-register"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setCookieConsentAgree()}
            >
              SOUHLASÍM
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allStrapiHeader {
          nodes {
            ForStudent
            ForTeacher
            Menu1
            Menu2
            Menu3
            Menu4
            Menu5
            id
          }
        }
      }
    `}
    render={(data) => <Header data={data} />}
  />
);
