import $ from "jquery";
import window from "global";

var re = new RegExp("(([a-z\\-]+)(?:\\.sk|\\.cz))", "img");
var domain = window?.location?.hostname;
if (domain?.search(re) > 0) domain = "." + domain?.match(re)[0];
var expires = 365;
var cookieConsentSet = false;
var cookieConsentPersonalization = false;
var cookieConsentAnalytics = false;

getCookieConsent();

export function displayCookieConsentInfo() {
  if (!cookieConsentSet) {
    $("#cookieConsent").fadeIn(400);
  }
}

export function setCookieConsentAgree() {
  setCookieConsentAll();
  $("#cookieConsent").fadeOut(400);
}

function getCookieConsent() {
  if (typeof navigator !== "undefined" && navigator.cookieEnabled) {
    cookieConsentSet = getCookie("cookieConsentSet") === "1";
    cookieConsentPersonalization =
      getCookie("cookieConsentPersonalization") === "1";
    cookieConsentAnalytics = getCookie("cookieConsentAnalytics") === "1";
  }

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag("consent", "default", {
    ad_storage: cookieConsentAnalytics ? "granted" : "denied",
    analytics_storage: cookieConsentAnalytics ? "granted" : "denied",
    personalization_storage: cookieConsentPersonalization
      ? "granted"
      : "denied",
  });
}

function setCookieConsentAll() {
  setCookie("cookieConsentSet", "1", expires, domain);
  setCookie("cookieConsentPersonalization", "1", expires, domain);
  setCookie("cookieConsentAnalytics", "1", expires, domain);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("consent", "update", {
    ad_storage: "granted",
    analytics_storage: "granted",
    personalization_storage: "granted",
  });
}

export function setCookieConsentPersonalization(value) {
  if (!cookieConsentSet) setCookie("cookieConsentSet", "1", expires, domain);
  setCookie("cookieConsentPersonalization", value ? "1" : "0", expires, domain);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("consent", "update", {
    personalization_storage: value ? "granted" : "denied",
  });
}

export function setCookieConsentAnalytics(value) {
  if (!cookieConsentSet) setCookie("cookieConsentSet", "1", expires, domain);
  setCookie("cookieConsentAnalytics", value ? "1" : "0", expires, domain);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("consent", "update", {
    analytics_storage: value ? "granted" : "denied",
  });
}

export function saveCookieConsent(personalization, analytics) {
  if (!cookieConsentSet) setCookie("cookieConsentSet", "1", expires, domain);
  setCookie(
    "cookieConsentPersonalization",
    personalization ? "1" : "0",
    expires,
    domain
  );
  setCookie("cookieConsentAnalytics", analytics ? "1" : "0", expires, domain);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("consent", "update", {
    ad_storage: analytics ? "granted" : "denied",
    analytics_storage: analytics ? "granted" : "denied",
    personalization_storage: personalization ? "granted" : "denied",
  });
}

function setCookie(c_name, value, exdays, domain) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  var c_value =
    escape(value) +
    (exdays == null ? "" : "; expires=" + exdate.toUTCString()) +
    (domain == null ? "" : "; domain=" + domain) +
    "; path=/";
  document.cookie = c_name + "=" + c_value;
}

function getCookie(c_name) {
  var i,
    x,
    y,
    ARRcookies = document.cookie.split(";");
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x === c_name) {
      return unescape(y);
    }
  }
}

function removeCookie(c_name, value, domain) {
  setCookie(c_name, "undefined", -1, domain);
}
