import React from "react";
import { graphql, StaticQuery, navigate } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "./Footer.scss";
import pointArrow from "../img/point-arrow.svg";
import logoVimTo from "../img/logo_vimto.svg";
import logoScio from "../img/scioLogo.svg";
import playSolid from "../img/play-solid.svg";

function Footer(data) {
  return (
    <div className="footer" id="footer">
      <div className="footer-container">
        <div className="footer-container-top">
          <div className="footer-container-top-help">
            {data.data.allStrapiFooterPage.nodes[0].WantHelp}
          </div>
          <div className="footer-container-top-buttons">
            <div className="footer-container-top-buttons-text">
              {data.data.allStrapiFooterPage.nodes[0].TryVimTo}
            </div>
            <div className="footer-container-top-buttons-container">
              <a
                href="https://youtu.be/Wx3_OcwCGuU"
                target="_blank"
                className="button-hover-effect footer-container-top-buttons-container-discover"
                rel="nofollow"
              >
                Video
                <img
                  src={playSolid}
                  className="footer-container-top-buttons-container-discover-video"
                  height="16"
                  alt="Play"
                />
              </a>
              <a
                href="/objednavka"
                className="button-hover-effect footer-container-top-buttons-container-discover"
                rel="nofollow"
              >
                Objednat
              </a>
              <a
                href="/go?pref=new&ref=hp"
                className="button-hover-effect footer-container-top-buttons-container-free"
                rel="nofollow"
              >
                Vímto zdarma
                <div className="footer-container-top-buttons-container-free-float">
                  <img
                    src={pointArrow}
                    className="footer-container-top-buttons-container-free-float-arrow"
                    alt="Arrow pointing up"
                  />
                  A bez registrace
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-container-bottom">
          <div className="footer-container-bottom-left">
            <AnchorLink
              to="/#header"
              className="footer-container-bottom-left-logo"
            >
              <img
                src={logoVimTo}
                height="20"
                className="footer-container-bottom-left-logo-svg"
                alt="VimTo Logo"
              />
              <p>Ladislav Kafka</p>
            </AnchorLink>
            <AnchorLink
              to="/#info"
              className="text-hover-effect footer-container-bottom-left-button"
            >
              {data.data.allStrapiFooterPage.nodes[0].Menu1}
            </AnchorLink>
            <AnchorLink
              to="/#faq"
              className="text-hover-effect footer-container-bottom-left-button"
            >
              {data.data.allStrapiFooterPage.nodes[0].Menu2}
            </AnchorLink>
            <AnchorLink
              to="/#pricing"
              className="text-hover-effect footer-container-bottom-left-button"
            >
              {data.data.allStrapiFooterPage.nodes[0].Menu3}
            </AnchorLink>
            <AnchorLink
              to="/#footer"
              className="text-hover-effect footer-container-bottom-left-button"
            >
              {data.data.allStrapiFooterPage.nodes[0].Menu4}
            </AnchorLink>
            <a
              rel="nofollow"
              className="text-hover-effect footer-container-bottom-left-button"
              href="tel:+420234705055"
            >
              Tel: 234 705 055
            </a>
          </div>
          <div className="footer-container-bottom-right">
            <div
              onClick={() => navigate("/gdpr")}
              className="text-hover-effect footer-container-bottom-right-button"
            >
              {data.data.allStrapiFooterPage.nodes[0].GDPR}
            </div>
            <div
              onClick={() => navigate("/obchodni-podminky")}
              className="text-hover-effect footer-container-bottom-right-button"
            >
              {data.data.allStrapiFooterPage.nodes[0].TermsAndConditions}
            </div>
            <div
              onClick={() => navigate("/cookies")}
              className="text-hover-effect footer-container-bottom-right-button"
            >
              {data.data.allStrapiFooterPage.nodes[0].Cookies}
            </div>
            <div
              onClick={() => navigate("/o-nas")}
              className="text-hover-effect footer-container-bottom-right-button"
            >
              {data.data.allStrapiFooterPage.nodes[0].ONAS}
            </div>
            <div className="footer-container-bottom-right-button">© 2021</div>
            <a
              rel="nofollow"
              href="https://scio.cz/"
              target="_blank"
              rel="noreferrer"
              className="footer-container-bottom-right-logo"
            >
              <img
                src={logoScio}
                height="20"
                className="footer-container-bottom-right-logo-svg"
                alt="SCIO Logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allStrapiFooterPage {
          nodes {
            Cookies
            GDPR
            Menu1
            Menu2
            Menu3
            Menu4
            Menu5
            TryVimTo
            ONAS
            TermsAndConditions
            WantHelp
            id
          }
        }
      }
    `}
    render={(data) => <Footer data={data} />}
  />
);
